<template>
  <div class="available-service-selector">
    <b-form-group>
      <template v-if="!disableTitle" v-slot:label>
        <Required
            v-if="required || forceSpecification"
            :key="alternativeTitle ? alternativeTitle : $t('views.availableServices.title')"
        >
          {{ alternativeTitle ? alternativeTitle : $t('views.availableServices.title') }}
        </Required>
        <template v-else>
          <span :key="alternativeTitle ? alternativeTitle : $t('views.availableServices.title')">
            {{ alternativeTitle ? alternativeTitle : $t('views.availableServices.title') }}
          </span>
        </template>
      </template>

      <b-form-select
        v-if="!disableGroupFilter"
        id="sla-select"
        v-model="selectedSlaTypeId"
        :options="groupedSLAs"
        value-field="id"
        text-field="text"
        :class="{ 'mb-2': groupedSlaOptions.length }"
        aria-describedby="cost_type-form-feedback"
        @input="resetOptions"
      />

      <b-form-radio-group
        v-if="groupedSlaOptions.length"
        v-model="v$.selectedOption.$model"
        aria-describedby="slaOptions-feedback"
        stacked
        :required="selectedSlaTypeId !== 'NONE'"
        name="sla-options"
        class="pt-3"
      >
        <div>
          <b-form-radio
            v-for="service in groupedSlaOptions"
            :key="service.id"
            :value="service.id"
            :disabled="!service.enabled"
            class="mb-2 service-radio form-control-extra"
            :class="{ 'selected': service.id === selectedOption }"
          >
            <div class="d-flex justify-content-between align-items-start">
              <span :key="contractor(service).name + service.name" class="name">{{ contractor(service).name }}: {{ service.name }}</span>

              <ContractorServicePriorityBadge
                :name="servicePriority(service).name"
                :color="servicePriority(service).color"
                class="service-priority-badge"
              />
            </div>
            <div v-if="hideRates" class="details cost-details">
              Unable to display rates when assigning this service against multiple sites with different currencies.
            </div>

            <div v-if="!hideRates" class="details cost-details" >
              <div v-if="$enums.CONTRACTOR_BILLING_TYPE.FIXED.eql(service.billing_type)">
                <font-awesome-icon
                  :icon="['fas', 'concierge-bell']"
                  fixed-width
                  class="label-icon"
                />
                {{ $t('views.availableServices.fixedFee', {fixedFee: $currency(service.billing_fixed_amount, currency.code)}) }}
              </div>
              <div v-else-if="$enums.CONTRACTOR_BILLING_TYPE.ASSET.eql(service.billing_type)">
                <font-awesome-icon
                  :icon="['fas', 'concierge-bell']"
                  fixed-width
                  class="label-icon"
                />
                {{ $t('views.availableServices.perAssetCalloutFee', {calloutFee: $currency(service.billing_fixed_amount, currency.code)}) }}
                <br>
                <font-awesome-icon
                  :icon="['far', 'box']"
                  fixed-width
                  class="label-icon"
                />
                {{ $t('views.availableServices.perAssetFee', {calloutFee: $currency(service.billing_per_asset_amount, currency.code)}) }}
              </div>
              <div v-else-if="$enums.CONTRACTOR_BILLING_TYPE.TIMED.eql(service.billing_type)">
                <div v-if="service.main_engineer_rate_id && getRateCardCurrency(service, currency)">
                  <div class="d-flex align-items-center">
                    <font-awesome-icon
                      :icon="['fas', 'concierge-bell']"
                      fixed-width
                      class="label-icon"
                    />

                    <div class="timed-callout-fee">
                      <span class="timed-callout-fee-standard">
                        {{ $t('views.availableServices.calloutFee', {
                            calloutFee: $currency(getRateCardCurrency(service, currency).standard_hours_callout, currency.code)
                           })
                        }}
                      </span>&nbsp;<!--
                      --><span class="timed-callout-fee-out-of-hours">
                        {{ $t('views.availableServices.calloutFeeOutOfHours', {
                            outOfHoursCalloutFee: $currency(getRateCardCurrency(service, currency).out_of_hours_callout, currency.code)
                          })
                        }}
                      </span>
                    </div>
                  </div>

                  <div class="d-flex align-items-center">
                    <font-awesome-icon
                      :icon="['far', 'stopwatch']"
                      fixed-width
                      class="label-icon"
                    />

                    <div class="timed-hourly-rate">
                      <span class="timed-hourly-rate-standard">
                        {{ $t('views.availableServices.hourlyRate', {
                            hourlyRate: $currency(getRateCardCurrency(service, currency).standard_hours_labour, currency.code)
                          })
                        }}
                      </span>&nbsp;<!--
                      --><span class="timed-hourly-rate-out-of-hours">
                        {{ $t('views.availableServices.hourlyRateOutOfHours', {
                             outOfHoursHourlyRate: $currency(getRateCardCurrency(service, currency).out_of_hours_labour, currency.code)
                           })
                        }}
                      </span>
                    </div>
                  </div>
                </div>
                <div v-else-if="!service.main_engineer_rate_id">
                  <span class="text-muted">
                    {{ $t('views.availableServices.noRateCardAdded') }}
                  </span>
                </div>
              </div>
              <div v-else>
                <span class="text-muted">
                  {{ $t('views.availableServices.noBillingType') }}
                </span>
              </div>
            </div>
            <div v-if="service.id === selectedOption" class="details working-hours">
              <template
                v-if="
                  contractors[(contractor(service)?.id ?? service.contractor?.id)] &&
                  Object.values(contractors[(contractor(service)?.id ?? service.contractor_id)].hours).length
                "
              >
                <div class="d-flex align-items-center mb-1">
                  <strong>{{ $t('views.availableServices.workingHours.title') }}</strong>
                  <b-button
                    variant="none"
                    class="text-muted btn-xs d-flex align-items-center p-1 ml-1 small toggle-working-hours"
                    @click="showWorkingHours = !showWorkingHours"
                  >
                    <font-awesome-icon
                      :icon="['far', showWorkingHours ? 'minus' : 'plus']"
                      class="fa-fw"
                    />
                  </b-button>
                </div>

                <div class="d-flex">
                  <div><font-awesome-icon :icon="['fas', 'clock']" fixed-width class="label-icon" /></div>
                  <div :key="workingHoursText(contractors[contractor(service)?.id ?? service.contractor_id].hours)" class="working-hours-summary">
                    {{ workingHoursText(contractors[contractor(service)?.id ?? service.contractor_id].hours) }}
                  </div>
                </div>

                <b-collapse v-model="showWorkingHours">
                  <ContractorWorkingHoursDisplay
                    :hours="contractors[contractor(service)?.id ?? service.contractor_id].hours"
                    class="working-hours-details"
                  />
                </b-collapse>
              </template>
              <template v-else>
                {{ $t('views.availableServices.noHoursAdded') }}
              </template>
            </div>
          </b-form-radio>
        </div>
      </b-form-radio-group>
      <span class="d-flex justify-content-center">
        <b-button
          v-if="numberSlaOptionsLeft > 0 && showAllOptions === false"
          variant="outline-secondary"
          class="mt-3"
          @click="showAllOptions = true"
        >
          {{ $t('views.availableServices.showMore', {number: numberSlaOptionsLeft}) }}
        </b-button>
      </span>
    </b-form-group>
  </div>
</template>

<script>
import { requiredIf } from '@vuelidate/validators';
import {
  BFormGroup,
  BFormRadio,
  BButton,
  BFormRadioGroup,
  BFormSelect,
  BCollapse,
} from 'bootstrap-vue';
import groupBy from 'lodash/groupBy';
import Required from '@/ux/form/Required.vue';
import ContractorServicePriorityBadge from '@/components/contractor/ContractorServicePriorityBadge.vue';
import ContractorWorkingHoursDisplay from '@/components/contractor/ContractorWorkingHoursDisplay.vue';
import ContractorRateAPI from '@/services/ContractorRateAPI.js';
import { mapGetters } from 'vuex';
import ContractorAPI from '@/services/ContractorAPI.js';
import useVuelidate from '@vuelidate/core';

const PROMPT_ID = 'PROMPT';
const SHOW_ALL_ID = 'SHOW_ALL';
const NO_SLA_ID = 'NONE';

export default {
  name: 'ServiceSelector',
  setup() {
    return { v$: useVuelidate() };
  },
  components: {
    ContractorServicePriorityBadge,
    ContractorWorkingHoursDisplay,
    BButton,
    BFormGroup,
    BFormRadio,
    BFormRadioGroup,
    BFormSelect,
    BCollapse,
    Required,
  },
  props: {
    required: {
      type: Boolean,
      default: false,
    },
    alternativeTitle: {
      type: String,
      default: '',
    },
    options: {
      type: Array,
      default: null,
    },
    hideRates: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [Number, String],
      default: null,
    },
    disableTitle: {
      type: Boolean,
      default: false,
    },
    disableGroupFilter: {
      type: Boolean,
      default: false,
    },
    slaTypeId: {
      type: [Number, String],
      default: null,
    },
    forceSpecification: {
      type: Boolean,
      default: false,
    },
    currency: {
      type: Object,
      default: () => {},
    },
  },
  validations: {
    selectedOption: {
      // eslint-disable-next-line func-names
      required: requiredIf(function () {
        return this.selectedSlaTypeId !== NO_SLA_ID;
      }),
    },
  },
  data() {
    return {
      originalOption: null,
      selectedSlaTypeId: PROMPT_ID,
      showAllOptions: false,
      contractors: {},
      rateCards: {},
      showWorkingHours: false,
    };
  },
  computed: {
    ...mapGetters(['hasPermission']),
    selectedOption: {
      get() {
        return this.value;
      },
      set(value) {
        if (value) {
          this.originalOption = value;
        }
        this.emitInput(value);
      },
    },
    promptOption() {
      return {
        id: PROMPT_ID,
        text: this.$t('common.forms.selectAnOption'),
        disabled: true,
        hidden: true,
      };
    },
    noneOption() {
      return {
        id: NO_SLA_ID,
        text: this.$t('common.forms.noneLabel'),
      };
    },
    allOption() {
      return {
        id: SHOW_ALL_ID,
        text: this.$t('views.availableServices.servicesAvailable', {
          service: this.$t('views.availableServices.showAll'),
          count: this.options.length,
        }),
      };
    },
    groupedSLAs() {
      const slaObject = groupBy(this.options, (option) => this.servicePriority(option)?.id ?? option.service_priority_id);

      const slaArrayByType = Object.entries(slaObject).reduce(
        (arr, [key, value]) =>
          [
            ...arr,
            ...[
              {
                id: key,
                text: this.$t('views.availableServices.servicesAvailable', {
                  service: this.servicePriority(value[0])?.name,
                  count: value.length,
                }),
              },
            ],
          ],
        [],
      );

      let options = [];

      if (this.forceSpecification) {
        options = [this.promptOption];
      }

      if (!this.required) {
        options = [...options, this.noneOption];
      }

      return [...options, ...slaArrayByType, this.allOption];
    },
    groupedSlaOptions() {
      if (this.showAllOptions) {
        return this.optionsBySelectedSlaType;
      }
      return this.optionsBySelectedSlaType.slice(0, 5);
    },
    optionsBySelectedSlaType() {
      return this.disableGroupFilter || this.selectedSlaTypeId === SHOW_ALL_ID
        ? this.options
        : this.options.filter(
            (option) => (option.service_priority_id ?? option.service_priority?.id) === Number(this.selectedSlaTypeId),
          );
    },
    numberSlaOptionsLeft() {
      return this.optionsBySelectedSlaType.length - this.groupedSlaOptions.length;
    },
    neededContractors() {
      if (!this.hasPermission('Contractor_View')) {
        return [];
      }

      const ids = this.groupedSlaOptions.map((s) => s.contractor_id).sort();

      return [...new Set(ids)];
    },
    neededRateCards() {
      if (!this.hasPermission('ContractorRate_View')) {
        return [];
      }

      const ids = this.groupedSlaOptions
        .filter((s) => this.$enums.CONTRACTOR_BILLING_TYPE.TIMED.eql(s.billing_type) && s.main_engineer_rate_id)
        .map((s) => s.main_engineer_rate_id)
        .sort();

      return [...new Set(ids)];
    },
  },
  watch: {
    neededContractors: {
      immediate: true,
      handler() {
        this.loadContractors();
      },
    },
    neededRateCards() {
      this.loadRateCards();
    },
  },
  created() {
    if (!this.disableGroupFilter) {
      this.selectSlaInit();
      this.originalOption = this.selectedOption;
    } else {
      this.loadRateCards();
    }
  },
  methods: {
    async resetOptions() {
      await this.$nextTick();
      this.showAllOptions = false;

      // if the selected option is no longer visible in the list, reset it
      if (!this.groupedSlaOptions.find((option) => option.id === this.selectedOption)) {
        // if the originally selected option is now visible in the list, select it
        if (this.groupedSlaOptions.find((option) => option.id === this.originalOption)) {
          this.selectedOption = this.originalOption;
        } else {
          this.selectedOption = null;
        }
      }

      this.$emit('selected-sla-type-changed', this.selectedSlaTypeId);
    },
    handleRadioClick(e) {
      // workaround padding not capturing clicks on b-form-radio elements
      // don't handle anything from children, as the radio element will properly
      if (e.target.classList.contains('service-radio')) {
        this.selectedOption = parseInt(e.target.querySelector('.custom-control-input').value, 10);
      }
    },
    emitInput(v) {
      this.$emit('input', v);
    },
    selectSlaInit() {
      const selectedOption = this.options.filter((op) => op.id === this.value)[0];
      if (selectedOption) {
        this.selectedSlaTypeId = this.servicePriority(selectedOption)?.id ?? selectedOption.service_priority_id;
      } else if (this.value === NO_SLA_ID) {
        this.selectedSlaTypeId = NO_SLA_ID;
      } else if (this.forceSpecification) {
        this.selectedSlaTypeId = PROMPT_ID;
      } else if (this.required === true) {
        this.selectedSlaTypeId = SHOW_ALL_ID;
      } else {
        this.selectedSlaTypeId = NO_SLA_ID;
      }
    },
    async loadContractors() {
      const missing = this.neededContractors.filter((id) => this.contractors[id] === undefined);
      if (missing.length) {
        this.contractors = {
          ...Object.fromEntries(missing.map((id) => [id, null])),
          ...this.contractors,
        };

        const res = await ContractorAPI.getContractors(null, null, null, {
          id: missing,
        });
        const newContractors = res.data;

        this.contractors = {
          ...this.contractors,
          ...Object.fromEntries(newContractors.map((r) => [r.id, r])),
        };
      }
    },
    async loadRateCards() {
      const missing = this.neededRateCards.filter((id) => this.rateCards[id] === undefined);
      if (missing.length) {
        this.rateCards = {
          ...Object.fromEntries(missing.map((id) => [id, null])),
          ...this.rateCards,
        };

        const newRateCards = await ContractorRateAPI.getContractorRate(null, null, null, {
          id: missing,
        });

        this.rateCards = {
          ...this.rateCards,
          ...Object.fromEntries(newRateCards.map((r) => [r.id, r])),
        };
      }
    },
    getRateCardCurrency(service, currency) {
      return this.rateCards[service.main_engineer_rate_id]?.currencies.find((c) => c.currency_id === currency.id);
    },
    workingHoursText(hours) {
      const day = this.$dayjs().day();
      let today;
      if (day === 0) {
        today = hours[this.$enums.CONTRACTOR_HOURS_DAYS.SUNDAY.value] ?? null;
      } else if (day === 6) {
        today = hours[this.$enums.CONTRACTOR_HOURS_DAYS.SATURDAY.value] ?? null;
      } else {
        today = hours[this.$enums.CONTRACTOR_HOURS_DAYS.WEEKDAYS.value] ?? null;
      }

      if (today) {
        if (today.start && today.finish) {
          const formatted = {
            start: today.start,
            finish: today.finish,
          };

          return today.out_of_hours
            ? this.$t('views.availableServices.workingHours.withOutOfHours', formatted)
            : this.$t('views.availableServices.workingHours.noOutOfHours', formatted);
        }

        if (today.out_of_hours) {
          return this.$t('views.availableServices.workingHours.outOfHoursOnly');
        }
      }

      return this.$t('views.availableServices.workingHours.closed');
    },

    contractor(service) {
      return service.contractor ?? service.Contractor;
    },

    servicePriority(service) {
      return service.service_priority ?? service.ServicePriority;
    }
  },
};
</script>

<style lang="scss" scoped>
.service-radio:deep(.custom-control-label) {
  width: 100%;
}

.service-radio {
  color: $gray-600;
  padding: 1rem 1rem 0.25rem 2.5rem;

  &.selected {
    background: adjust-lightness($info, 55%);
    border-color: $gray-500;
  }

  .name {
    font-weight: bold;
  }

  .details {
    font-size: 0.9em;
    line-height: 1.6;
    padding: 0.25em 0 0.75em;

    & + .details {
      padding: 0.75em 0;
      border-top: 1px solid #828282;
    }
  }
}

.timed-callout-fee-out-of-hours, .timed-hourly-rate-out-of-hours {
  white-space: nowrap;
}

.service-priority-badge {
  font-weight: normal;
  margin-left: 2rem;
  white-space: nowrap;
}

@include media-breakpoint-down(sm) {
  .service-priority-badge {
    white-space: normal;
    max-width: 100px;
  }
}

.toggle-working-hours {
  border: 2px solid #828282;
}

.label-icon {
  margin-right: 0.25em;
}

.working-hours-summary {
  line-height: normal;
}

.working-hours-details {
  font-size: 0.9em;
  margin-left: calc(1.5em / 0.9);
  line-height: 1.4;
}
</style>
